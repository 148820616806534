import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/journal/2",
  "date": "2020-02-19",
  "category": "Writing",
  "description": "Ten things I've learned through my experiences of working on many different teams in no particular order. ",
  "title": "10 Lessons in Teamwork"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`1: Provide guidance to newcomers`}</h2>
    <p>{`Things can be intimidating when you're new to something or someplace. You know, afterall, you've been there before. Be the voice that relieves newcomers of that anxiety. Show them that it's okay to ask questions and that it's not necessary to know everything. This develops team bonds as well as encourages learning/growth.  `}</p>
    <h2>{`2: Don't be afraid to look stupid`}</h2>
    <p>{`Great questions are left unasked all too often due to a fear of judgement. The fact of the matter is that oftentimes you have a question you're afraid to ask, you're not the only one thinking it. Even if such is not the case, you only benefit the team by clarifying any points you may not fully understand. A question's value might even be such that by breaking an idea in discussion, you prevent an idea from breaking the team in its manifestation.  `}</p>
    <h2>{`3: Be aware of the sense of a lack of direction`}</h2>
    <p>{`When your team lacks direction, provide direction. At the very least, ask the right questions to bring the lack of direction to the team's awareness. Action without direction is ineffective and should be taken into consideration in all planning.  `}</p>
    <h2>{`4: Sometimes laughter can be the greatest contributor to productivity`}</h2>
    <p>{`Work is the only force that drives progress forward. However, in situations when team morale is low, laughter is more necessary than work to make work work.  `}</p>
    <h2>{`5: Know when to guide and when to be guided`}</h2>
    <p>{`Team work, from the perspective of the individual, is a balance of guiding and being guided. One member should not be commanding the team at all times, nor should they find themselves simply listening to everything without providing their own input. Harmony is found in a team where all members provide input and guidance as well as listen to anyone else who is speaking. Such allows the group to move together as if they were a single entity.  `}</p>
    <h2>{`6: Be thorough and concise`}</h2>
    <p>{`Be clear with your words, and use as few as necessary to get a point across.  `}</p>
    <h2>{`7: Own your word`}</h2>
    <p>{`If you say you'll do something for someone, remain committed in your efforts to accomplish it. At the same time, if you say something is worth doing but realize it's actually inconsequential or doesn't make sense anymore, be able to own that statement too and say "never mind".  `}</p>
    <h2>{`8: Be the hardest worker in the room`}</h2>
    <p>{`Learn from the doers that contribute most around you. Don't let yourself work less than your team mates. The best way to do this is to hold yourself to a higher expectation than your workplace.  `}</p>
    <h2>{`9: Give credit where credit is due`}</h2>
    <p>{`Reward hard work on the part of others. Achievement through decisive action toward a common objective is something to be celebrated. This maintains team morale and ensures that others feel valued for their efforts.  `}</p>
    <h2>{`10: You're not always right. Listen`}</h2>
    <p>{`Never assume someone is wrong. Never assume that you are right. Don't get too attached to any one idea, especially your own. All ideas are worthy of debate. Welcome disagreements and always keep truth as priority in all discussions.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      